import React from 'react'
import * as moment from 'moment'

import Layout from "../components/layout"
import SEO from "../components/seo"

const Post = ({ pageContext }) => {
    const { title, content, date } = pageContext
    const cleanedContent = content.replace(/http:\/\//gi, 'https://').replace(/wyblog\//gi, '');

    return (
        <Layout>
            <SEO title={title} />
            <div className="box">
                <div className="title has-text-primary">
                    {title}
                </div>
                <div className="content has-text-grey is-size-6">
                    <p>
                        Published {moment(date).format('Do MMM YYYY')}
                    </p>
                </div>
                <div className="content">
                    <p dangerouslySetInnerHTML={{ __html: cleanedContent }}></p>
                </div>
            </div>
        </Layout>
    )
}

export default Post